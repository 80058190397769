import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import StatusRoute from './routes/status/index';
import ReportRoute from './routes/report/index';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import { HookedBrowserRouter as Router } from './HookedBrowserRouter';
import client from './client';

ReactDOM.render(
	<ApolloProvider client={client}>
		<Router>
			<Switch>
				<Route path='/90day' exact component={ReportRoute} />
				<Route path='/passport/:passport_token/visa/:visa_stamp_id' exact component={StatusRoute} />
				<Route path='/passport/:passport_token/report/:visa_stamp_id' exact component={StatusRoute} />
				<Route path='/passport/:passport_token/report' exact component={ReportRoute} />
				
				<Route component={() => {
					const redirectionURL = 'https://thaivisacentre.com';
					
					useEffect(() => {
						window.location.href = redirectionURL;
					});

					return <div>Redirecting to {redirectionURL}</div>
				}} />
			</Switch>
		</Router>
	</ApolloProvider>,
	document.getElementById('root')
);

serviceWorker.unregister();
