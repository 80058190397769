import React from 'react';
import styles from './Loader.module.scss';

const Loader = ({ message = 'LOADING', width = 200, height, marginTop }: {message?: string, width?: number, height?:any, marginTop?:any}) => {
	return <div className={styles.container} style={{width, height, marginTop }}>
        <div className={styles.loader}>
            <div></div>
        </div>
        <div>{ message ? message : null}</div>
    </div>;
};

export default Loader;