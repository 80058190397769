import React, {useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Loader from '../../Loader';
import client from '../../client';
import Jimp from 'jimp';
import { useDropzone } from 'react-dropzone';
import Promise from 'bluebird';
import styles from './index.module.scss';



import gql from 'graphql-tag';

const PASSPORT_LOOKUP_QUERY_RESULT = gql`
	query PassportLookup($document_number: String!, $birth_date: date!) {
		passport: public_passport_lookup(document_number: $document_number, birth_date: $birth_date) {
			passport_token
		}
	}
`;

const PASSPORT_IMAGE_LOOKUP_QUERY_RESULT = gql`
	query LookupPassportByImage($image: String!) {
		public_passport_image_lookup(image: $image) {
			passport_token
		}
	}
`;

let DAYS = [{name: '--', value: '--'}].concat(new Array(31).fill(0).map((value, key) => ({
	name: String(key + 1).padStart(2, '0'),
	value: String(key + 1).padStart(2, '0')
})));
let YEARS = [{name: '--', value: '--'}].concat(new Array(110).fill(0).map((value, key) => ({
	name: String(new Date().getFullYear() - key),
	value: String(new Date().getFullYear() - key)
})));
let MONTHS = [{name: '--', value: '--'}].concat(new Array(12).fill(0).map((value, key) => ({
	name: String(key + 1).padStart(2, '0'),
	value: String(key + 1).padStart(2, '0')
})));

function base64blob (blob:any) {
	return new Promise((resolve:any) => {
		let reader = new FileReader();
		reader.readAsDataURL(blob); 
		reader.onloadend = function() {
			let base64data:any = reader.result;
			resolve(base64data?.split(/base64,/)[1]);
		}
	});
}

function StepDropzone (props:any) {
	const [isUploading, setIsUploading] = useState(false);

	async function uploadPhoto(image: any) {
		setIsUploading(true);

		image = await Jimp.read(image.preview);

		if (image.bitmap.width > 1000) {
			image = await image.resize(1500, Jimp.AUTO, Jimp.RESIZE_BEZIER);
		}
		image = new Blob([await image.quality(80).getBufferAsync(Jimp.MIME_JPEG)], {type: 'image/jpeg'});

		let { data } = await client.query({
			query: PASSPORT_IMAGE_LOOKUP_QUERY_RESULT,
			variables: {image: await base64blob(image)}
		});
		
		if (data?.public_passport_image_lookup?.passport_token) {
			window.location.href = `/passport/${data.public_passport_image_lookup.passport_token}/report`;
			return true;
		} else {
			setIsUploading(false);
			return false;
		}
	}

	const {getRootProps, getInputProps} = useDropzone({
		onDrop: async (acceptedFiles: any) => {
			acceptedFiles = acceptedFiles.map((acceptedFile:any) => {
				return Object.assign(acceptedFile, {
					preview: URL.createObjectURL(acceptedFile)
				});
			});

			let images = acceptedFiles; // (await Promise.map(acceptedFiles, uploadPhoto)).map((item:any) => item.image);
			let image = images[0];

			if (image) {
				if (!await uploadPhoto(image)) {
					props.onUploadFailed(true);
				}
			}
		}
	});

	return <div>
		<Alert severity="success" icon={false} style={{marginBottom: 15, fontSize: 13}}>This is a <b>secure link</b>, and your information is <b>safe</b>.</Alert>
		<Alert severity="info" icon={false} style={{marginBottom: 15, fontSize: 14}}>To access our <b>90 day reporting</b> request system you must provide a photo of your <b>passport information page</b> to verify that you are a <b>Thai Visa Centre</b> customer.</Alert>
		<Alert severity="warning" icon={false} style={{marginBottom: 15, fontWeight: 'bold'}}>
			<div>MUST BE 5-10 DAYS BEFORE DUE DATE!</div>
		</Alert>
		<div {...getRootProps({className: styles.dropzone + ' ' + styles.passportBackgroundImage})} style={{opacity: isUploading ? 0.7 : 1}}>
			{(() => {
				return <>
					<input {...getInputProps()} />
					<div style={{marginTop: 120}}>{isUploading ? <Loader marginTop={0} message="ANALYZING PHOTO..." width={220} /> : props.buttonLabel}</div>
				</>;
			})()}
		</div>
	</div>;
}

export default function PassportLookup(props:any) {
	const [birthDay, setBirthDay] = useState('--');
	const [birthMonth, setBirthMonth] = useState('--');
	const [birthYear, setBirthYear] = useState('--');
	const [passportNumber, setPassportNumber] = useState('');
	const [errorMessage, setErrorMessage] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showPassportUpload, setShowPassportUpload] = useState(true);

	if (showPassportUpload) {
		return <StepDropzone buttonLabel={<div>UPLOAD<br/>PASSPORT PHOTO</div>} onUploadFailed={(response:any) => {
			setShowPassportUpload(false);
			// console.log(response);
		}} />;
	}

	if (isLoading) {
		return <Loader message="LOADING" width={150} />;
	}
	
	return <div style={{
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	}}>
		<Alert severity="success" icon={false} style={{marginBottom: 15, fontSize: 13}}>This is a <b>secure link</b>, and your information is <b>safe</b>.</Alert>
		<Alert severity="info" icon={false} style={{marginBottom: 15, fontSize: 13}}>To access to the <b>Thai Visa Centre</b> reporting request system you must enter the following information.</Alert>
		{errorMessage ? <Alert severity="error" icon={false} style={{marginBottom: 15, fontWeight: 'bold', fontSize: 13}}>{errorMessage}</Alert> : null}
		
		<TextField
			label="Passport Number"
			value={passportNumber}
			fullWidth
			error={!passportNumber}
			style={{marginBottom: 20}}
			onChange={(event:any) => setPassportNumber(event.target.value.toUpperCase())} />

		<div style={{marginLeft: 0}}>
			<FormControl>
				<InputLabel htmlFor="birth-day">Birth Day</InputLabel>
				<Select
					style={{marginRight: 20, width: 50}}
					native
					error={birthDay === '--'}
					value={birthDay}
					onChange={(event:any) => {
						setBirthDay(event.target.value);
					}}
					inputProps={{
						name: 'birth-day',
						id: 'birth-day',
					}}
				>
					{DAYS.map((item:any, key:any) => <option key={key} value={item.value}>{item.name}</option>)}
				</Select>
			</FormControl>
			<FormControl>
				<InputLabel htmlFor="birth-month">Birth Month</InputLabel>
				<Select
					style={{marginRight: 20, width: 75}}
					native
					error={birthMonth === '--'}
					value={birthMonth}
					onChange={(event:any) => {
						setBirthMonth(event.target.value);
					}}
					inputProps={{
						name: 'birth-month',
						id: 'birth-month',
					}}
				>
					{MONTHS.map((item:any, key:any) => <option key={key} value={item.value}>{item.name}</option>)}
				</Select>
			</FormControl>
			<FormControl>
				<InputLabel htmlFor="birth-year">Birth Year</InputLabel>
				<Select
					style={{width: 75}}
					native
					error={birthYear === '--'}
					value={birthYear}
					onChange={(event:any) => {
						setBirthYear(event.target.value);
					}}
					inputProps={{
						name: 'birth-year',
						id: 'birth-year',
					}}
				>
					{YEARS.map((item:any, key:any) => <option key={key} value={item.value}>{item.name}</option>)}
				</Select>
			</FormControl>
		</div>

		<div>
			<Button color="secondary" disabled={birthDay === '--' || birthMonth  === '--' || birthYear === '--' || !passportNumber} variant="contained" style={{float: 'right', marginTop: 30}} onClick={async () => {
				setIsLoading(true);
				let { data } = await client.query({
					query: PASSPORT_LOOKUP_QUERY_RESULT,
					variables: {
						birth_date: `${birthYear}-${birthMonth}-${birthDay}`,
						document_number: passportNumber
					}
				});

				let passportToken = data?.passport?.passport_token;

				if (passportToken) {
					window.localStorage.setItem(`${birthYear}-${birthMonth}-${birthDay}`, '1');
					window.location.href = `/passport/${passportToken}/report`;
				} else {
					setIsLoading(false);
					setErrorMessage(<div>Details are incorrect, please try again.<br /><br />If you are sure they are correct please contact our staff to confirm.</div>);
					setBirthDay('--');
					setBirthMonth('--');
					setBirthYear('--');
					setPassportNumber('');
				}
			}}>LOGIN</Button>
		</div>
	</div>;
}