import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import Loader from '../../Loader';

const saw = require('string-saw');

const TRACKING_NUMBER_QUERY_RESULT = gql`
	query GetShipmentTracking($tracking_number:String!, $carrier_code:String!) {
		public_shipment_tracking(tracking_number: $tracking_number, carrier_code: $carrier_code) {
			tracking
		}
	}
`;

function Tracking(props:any) {
	let carrierCode = saw(props.trackingNumber).match(/([A-Z]+):/).first().toString();
	if (carrierCode === 'KERRY') {
		carrierCode = 'kerryexpress-th';
	} else if (carrierCode === 'EMS') {
		carrierCode = 'thailand-post';
	} else {
		carrierCode = 'kerryexpress-th';
	}
	let trackingNumber = saw(props.trackingNumber).split(/:/).last().toString() || props.trackingNumber;

	let { data, loading } = useQuery(
		TRACKING_NUMBER_QUERY_RESULT,
		{ variables: {
			tracking_number: trackingNumber,
			carrier_code: carrierCode
		}, fetchPolicy: 'cache-first' }
	);

	function renderTrackingHeader() {
		return <div>
			{carrierCode === 'kerryexpress-th' ? <h3 style={{fontSize: 21}}>
				Kerry Express Tracking<br />
				<a href={`https://th.kerryexpress.com/th/track/?track=${trackingNumber}`} style={{color: '#f50057'}}>{trackingNumber}</a>
			</h3> : null}
			{carrierCode === 'thailand-post' ? <h3 style={{fontSize: 21}}>
				Thailand Post (EMS)<br />
				<a href={`https://track.thailandpost.co.th/?trackNumber=${trackingNumber}`} style={{color: '#f50057'}}>{trackingNumber}</a>
			</h3> : null}
		</div>;
	}
        
	if (loading) {
		return <div style={{marginTop: 30, transformOrigin: '0 0', transform: 'scale(0.7)'}}><Loader message={carrierCode === 'kerryexpress-th' ? 'Kerry Express Loading...' : 'Thailand Post Loading...'} width={300} marginTop={0} height={20}  /></div>;
	} else {
		if (!data?.public_shipment_tracking?.tracking) {
			return renderTrackingHeader();
		}
		
		let { public_shipment_tracking: { tracking }} = data;

		return <div>
			{renderTrackingHeader()}
			{tracking.slice(0).reverse().map((status:any, key:number, array:any) => {
				
				return <div key={key} style={{
					fontSize: 10,
					border: '1px solid #ccc',
					padding: 5,
					marginTop: 10,
					marginBottom: 10,
					opacity: key !== array.length - 1 ? 0.7 : 1
				}}>
					<b>{moment(status.Date).format('MMM Do hh:mma')}<br />{saw(status.Details).split(' - ').trim().last().toString()}</b>
					<div style={{fontSize: 16, fontWeight: 'bold', color: '#3498ff'}}>{status.StatusDescription}</div>
				</div>;
			})}
		</div>;
	}
}

export default Tracking;