import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import 'rsuite/dist/styles/rsuite-default.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loader from '../../Loader';
import Timeline from './Timeline';
import Alert from '@material-ui/lab/Alert';
import Promise from 'bluebird';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSwipeable } from 'react-swipeable';

const VISA_STATUS_QUERY_RESULT = gql`
	query GetVisaStatus ($id:uuid!, $passport_token:String!) {
		public_visa_status(passport_token: $passport_token, id: $id) {
			passport_mailing_address
			passport_mailing_address_en
			passport_mailing_address_th
			passport_phone
			passport_first_name
			passport_last_name
			passport_birth_date
			visa_stamp_photo
			updated_at
			types
			type
			rush_date
			payment_type
			payment_time
			payment_status
			notes
			issue_date
			id
			hash
			fee
			expiration_date
			entry_stamp_type
			delivery_type
			phone
			address
			timeline
			is_agent
		}
	}
`;

let DAYS = [{name: '--', value: '--'}].concat(new Array(31).fill(0).map((value, key) => ({
	name: String(key + 1).padStart(2, '0'),
	value: String(key + 1).padStart(2, '0')
})));
let YEARS = [{name: '--', value: '--'}].concat(new Array(110).fill(0).map((value, key) => ({
	name: String(new Date().getFullYear() - key),
	value: String(new Date().getFullYear() - key)
})));
let MONTHS = [{name: '--', value: '--'}].concat(new Array(12).fill(0).map((value, key) => ({
	name: String(key + 1).padStart(2, '0'),
	value: String(key + 1).padStart(2, '0')
})));

const REPORT_STATUS_QUERY_RESULT = gql`
	query GetReport ($id:uuid!, $passport_token:String!) {
		public_reporting_status(passport_token: $passport_token, id: $id) {
			passport_first_name
			passport_last_name
			passport_mailing_address
			passport_mailing_address_en
			passport_mailing_address_th
			passport_phone
			passport_birth_date
			id
			fee
			address
			phone
			reporting_date
			timeline
			updated_at
			created_at
		}
	}
`;

const VISA_STATUS_MUTATION = gql`
    mutation UpdateVisaStatus ($id:uuid!, $passport_token:String!, $address:String, $phone:String, $delivery_type:String) {
        public_visa_status_update(id: $id, passport_token: $passport_token, address: $address, phone: $phone, delivery_type: $delivery_type) {
            affected_rows
        }
    }
`;

const REPORT_STATUS_MUTATION = gql`
    mutation UpdateReportStatus ($id:uuid!, $passport_token:String!, $address:String, $phone:String) {
        public_report_status_update(id: $id, passport_token: $passport_token, address: $address, phone: $phone) {
            affected_rows
        }
    }
`;


function App(props:any) {
    const [birthDate, setBirthDate] = useState<string>('');
	const [phone, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const [birthDay, setBirthDay] = useState('--');
	const [birthMonth, setBirthMonth] = useState('--');
	const [birthYear, setBirthYear] = useState('--');
	const [deliveryType, setDeliveryType] = useState('EMS/Kerry Express');

	let unlockEvents:any = [];

	const handlers = useSwipeable({ onSwiped: (event:any) => {
		if (unlockEvents.length === 0 && event.dir === 'Right') {
			unlockEvents.push('right');
		} else if (unlockEvents.length === 1 && event.dir === 'Right') {
			unlockEvents.push('right');
		} else if (unlockEvents.length === 2 && event.dir === 'Right') {
			unlockEvents.push('right');
		} else if (unlockEvents.length === 3 && event.dir === 'Left') {
			unlockEvents.push('left');
		} else if (unlockEvents.length === 4 && event.dir === 'Left') {
			unlockEvents.push('left');
		} else if (unlockEvents.length === 5 && event.dir === 'Right') {
			unlockEvents.push('right');
			window.localStorage.setItem('42', '1');
			setBirthDate(status.passport_birth_date);
		} else {
			unlockEvents = [];
		}
	}});

	let statusType:any,
		statusQuery:any;

	if (props.match.path === '/passport/:passport_token/report/:visa_stamp_id') {
		statusType = 'report';
		statusQuery = REPORT_STATUS_QUERY_RESULT;
	} else if (props.match.path === '/passport/:passport_token/visa/:visa_stamp_id') {
		statusType = 'visa';
		statusQuery = VISA_STATUS_QUERY_RESULT;
	}
	
	const [updateVisaStatus] = useMutation(VISA_STATUS_MUTATION, {
		refetchQueries: () => [{
			query: statusQuery,
			variables: {
				id: props.match.params.visa_stamp_id,
				passport_token: props.match.params.passport_token
			}
		}]
	});

	const [updateReportStatus] = useMutation(REPORT_STATUS_MUTATION, {
		refetchQueries: () => [{
			query: statusQuery,
			variables: {
				id: props.match.params.visa_stamp_id,
				passport_token: props.match.params.passport_token
			}
		}]
	});
	
	let { data, loading, error } = useQuery(
		statusQuery,
		{ variables: { 
			id: props.match.params.visa_stamp_id,
			passport_token: props.match.params.passport_token
		}, fetchPolicy: 'cache-first' }
	);

	let status:any;

	useEffect(() => {
		if (data && status) {
			setDeliveryType(status.delivery_type);
			setPhone(status.phone || status.passport_phone);
			setAddress(status.address || status.passport_mailing_address_th || status.passport_mailing_address_en);

			// eslint-disable-next-line
			(window as any).FS.identify(props.match.params.passport_token, {
				displayName: `${status.passport_first_name} ${status.passport_last_name}`.trim()
			});
		} else if (error) {
			window.location.href = 'https://thaivisacentre.com';
		}

		window.addEventListener('keydown', onKeyDown);

		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};

		// eslint-disable-next-line
    }, [data, status, error]);
	
	if (loading || error || isLoading) {
		return <Loader message="LOADING" width={150} />;
	}

	if (statusType === 'report') {
		status = data.public_reporting_status;
	} else if (statusType === 'visa') {
		status = data.public_visa_status;
	}

	function onKeyDown (event:any) {
		if (unlockEvents.length === 0 && event.code === 'ArrowRight') {
			unlockEvents.push('right');
		} else if (unlockEvents.length === 1 && event.code === 'ArrowRight') {
			unlockEvents.push('right');
		} else if (unlockEvents.length === 2 && event.code === 'ArrowRight') {
			unlockEvents.push('right');
		} else if (unlockEvents.length === 3 && event.code === 'ArrowLeft') {
			unlockEvents.push('left');
		} else if (unlockEvents.length === 4 && event.code === 'ArrowLeft') {
			unlockEvents.push('left');
		} else if (unlockEvents.length === 5 && event.code === 'ArrowRight') {
			unlockEvents.push('right');
			window.localStorage.setItem('42', '1');
			setBirthDate(status.passport_birth_date);
		} else {
			unlockEvents = [];
		}
	}

	function onBirthDateChange ({day, month, year}:any) {
		let value = `${year || birthYear || '--'}-${month || birthMonth || '--'}-${day || birthDay || '--'}`;

		setBirthDate(value);

		if (status.passport_birth_date === value) {
			window.localStorage.setItem(status.passport_birth_date, '1');
		}
	}

	if (
		!window.localStorage.getItem('42') &&
		!status.is_agent &&
		!window.localStorage.getItem(status.passport_birth_date) && 
		new URL(window.document.location.toString()).searchParams.get('birthDate') !== status.passport_birth_date &&
		birthDate !== status.passport_birth_date
	) {
		return <div className={styles.App} {...handlers}>
			<Alert severity="success" icon={false} style={{marginBottom: 15}}>This is a <b>secure link</b>, and your information is <b>safe</b>.</Alert>
			<Alert severity="error" icon={false} style={{marginBottom: 15}}>
				To view the content on this page you must confirm the <b>DATE OF BIRTH</b> for <b>{status.passport_first_name}</b>.
	{birthDate && birthDay !== '--' && birthMonth !== '--' && birthYear !== '--' ? <><br /><br /><b>{birthDate}</b> is not correct, please try again</> : null}	
			</Alert>

			<div style={{marginLeft: 80}}>
				<FormControl>
					<InputLabel htmlFor="birth-day">Day</InputLabel>
					<Select
						style={{marginRight: 20}}
						native
						value={birthDay}
						error={birthDay === '--'}
						onChange={(event:any) => {
							setBirthDay(event.target.value);
							onBirthDateChange({day: event.target.value});
						}}
						inputProps={{
							name: 'birth-day',
							id: 'birth-day',
						}}
					>
						{DAYS.map((item:any, key:any) => <option key={key} value={item.value}>{item.name}</option>)}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel htmlFor="birth-month">Month</InputLabel>
					<Select
						style={{marginRight: 20}}
						native
						error={birthMonth === '--'}
						value={birthMonth}
						onChange={(event:any) => {
							setBirthMonth(event.target.value);
							onBirthDateChange({month: event.target.value});
						}}
						inputProps={{
							name: 'birth-month',
							id: 'birth-month',
						}}
					>
						{MONTHS.map((item:any, key:any) => <option key={key} value={item.value}>{item.name}</option>)}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel htmlFor="birth-year">Year</InputLabel>
					<Select
						native
						value={birthYear}
						error={birthYear === '--'}
						onChange={(event:any) => {
							setBirthYear(event.target.value);
							onBirthDateChange({year: event.target.value});
						}}
						inputProps={{
							name: 'birth-year',
							id: 'birth-year',
						}}
					>
						{YEARS.map((item:any, key:any) => <option key={key} value={item.value}>{item.name}</option>)}
					</Select>
				</FormControl>
			</div>

		</div>;
		/*
			<TextField
				fullWidth
				id="date"
				label="Birth Date"
				type="date"
				error={true}
				value={birthDate}
				InputLabelProps={{
					shrink: true,
				}}
				onChange={(date:any) => {
					setBirthDate(moment(date.target.value).format('YYYY-MM-DD'));

					if (status.passport_birth_date === moment(date.target.value).format('YYYY-MM-DD')) {
						window.localStorage.setItem(status.passport_birth_date, '1');
					}
				}} 
			/>
		*/
	}

	const isMissingDetails = !phone || !address;

	return (
		<div className={styles.App}>
			<Alert severity="success" icon={false} style={{marginBottom: 15}}>This is a <b>secure link</b>, and your information is <b>safe</b>.</Alert>

			{!isMissingDetails || status.is_agent ? <Timeline status={status} statusType={statusType} /> : null}

			{!status.is_agent ? <Alert severity="info" icon={false} style={{marginBottom: 15}}>
				<div>Please verify your <b>address</b>, and <b>phone</b> is correct.</div>
				<div>This info will be used for used for contact, and/or delivery.</div>

				{isMissingDetails ? <div style={{marginTop: 20, fontWeight: 'bold'}}>Once you have provided the required details you will be able to see your visa application status.</div> : null}
			</Alert> : null }

			{!status.is_agent ? <div style={{marginTop: 25, paddingTop: 5, overflow: 'hidden'}}>
				{statusType === 'visa' ? <FormControl style={{minWidth: '100%', marginBottom: 18}} variant="outlined">
					<InputLabel style={{background: '#ffffff', padding: '0px 5px'}}>Delivery Type</InputLabel>
					<Select
						fullWidth
						value={deliveryType}
						error={!deliveryType}
						onChange={(event:any) => setDeliveryType(event.target.value)} >
						<MenuItem value={'Office Pickup'}>Office Pickup</MenuItem>
						<MenuItem value={'Messenger Service'}>Messenger Service</MenuItem>
						<MenuItem value={'EMS/Kerry Express'}>EMS/Kerry Express</MenuItem>
					</Select>
				</FormControl> : null}
				{deliveryType !== 'Office Pickup' || !phone || !address ? <>
					<TextField
						label="Phone"
						value={phone}
						error={!phone}
						variant="outlined"
						fullWidth
						style={{marginBottom: 20}}
						onChange={(event:any) => setPhone(event.target.value)} />
					<TextField
						label="Full Thai Home Address (Thai language)"
						multiline
						value={address}
						error={!address}
						variant="outlined"
						fullWidth
						rows={3}
						rowsMax={5}
						style={{marginBottom: 10}}
						onChange={(event:any) => setAddress(event.target.value)} />
				</> : null}
				<Button color="secondary"  variant="contained" style={{float: 'right'}} onClick={async () => {
					setIsLoading(true);
					if (statusType === 'visa') {
						await updateVisaStatus({
							variables: {
								id: props.match.params.visa_stamp_id,
								passport_token: props.match.params.passport_token,
								phone,
								address,
								delivery_type: deliveryType
							}
						});
					} else if (statusType === 'report') {
						await updateReportStatus({
							variables: {
								id: props.match.params.visa_stamp_id,
								passport_token: props.match.params.passport_token,
								phone,
								address
							}
						});
					}
					await Promise.delay(1000);
					setIsLoading(false);
				}}>
					UPDATE CONTACT / DELIVERY INFO
				</Button>
			</div> : null }
		</div>
	);
}

export default App;
