import React, { useState } from 'react';
import styles from './Timeline.module.scss';
import 'rsuite/dist/styles/rsuite-default.css';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Steps } from 'rsuite';
import Tracking from './Tracking';

const useImageViewerClasses = makeStyles(theme => ({
    paper: {
        maxWidth: 'min-content',
        height: 'fit-content'
    }
}));

function Timeline(props:any) {
	const [imageViewerImage, setImageViewerImage] = useState({url: ''});
	const imageViewerClasses = useImageViewerClasses();

    function onImageViewerClose () {
        setImageViewerImage({url: ''});
	}
	
    function onShowImageClick (image:any) {
        return (event:any) => {
            setImageViewerImage(image);
        }
    }


	// const timeline = [
	// 	{
	// 		created_at: '',
	// 		type: 'PASSPORT RECEIVED',
	// 		images: ['https://uploads.thaivisacentre.com/uploaded-photos/62ded06397e30c9993ac8b4bad57768e/fa7cd1ca87604fcc2a2eb4e6e8d5bd0d','https://uploads.thaivisacentre.com/uploaded-photos/62ded06397e30c9993ac8b4bad57768e/5c4d8882d186b93e2370b182db3b8213']
	// 	},
	// 	{
	// 		created_at: '',
	// 		type: 'PAYMENT CONFIRMED',
	// 		images: ['https://uploads.thaivisacentre.com/uploaded-photos/62ded06397e30c9993ac8b4bad57768e/e0546a767364da6ccc45309389e4477a']
	// 	},
	// 	{
	// 		created_at: '',
	// 		type: 'APPLICATION IN PROGRESS',
	// 		images: []
	// 	},
	// 	{
	// 		created_at: '',
	// 		type: 'VISA COMPLETED',
	// 		images: []
	// 	},
	// 	{
	// 		created_at: '',
	// 		type: 'PASSPORT READY FOR RETURN',
	// 		images: []
	// 	}
	// ];
    
    const { status, statusType } = props;

    let completedItems = status.timeline.filter((event:any) => event.completed).length;

    if (completedItems === 1) {
        completedItems = 2;
    }

	return <div>
		<Steps current={completedItems || 0} vertical className={styles.steps}>
			{status.timeline.map((item:any, key:number) => {
                let eventType:string = item.type;
                let description:any;

                if (eventType === 'PASSPORT RECEIVED') {
                    description = `${status.passport_first_name} ${status.passport_last_name}`;
                } else if (eventType === 'PASSPORT READY FOR RETURN') {
                    if (status.delivery_type === 'EMS/Kerry Express') {
                        eventType = 'PASSPORT READY FOR POST DELIVERY';
                    } else if (status.delivery_type === 'Messenger Service') {
                        eventType = 'PASSPORT READY FOR COURIER DELIVERY';
                    } else if (status.delivery_type === 'Office Pickup') {
                        eventType = 'PASSPORT READY FOR OFFICE PICKUP';
                    }
                } else if (eventType === 'APPLICATION IN PROGRESS') {
                    let TIMESPANS = {
                        '3-4 DAYS': [
                            '1 Year Retirement Extension',
                            '1 Year Retirement Extension (OA)'        
                        ],
                        '1-2 WEEKS': [
                            '1 Year Thai child Extension',
                            '1 Year Volunteer Extension',
                            '1 Year ED Extension',
                            '2 Months Visit Thai Family',
                            '1 Months Medical Extension',
                            '2 Months Medical Extension',
                            '3 Months Medical Extension'
                        ],
                        '3-4 WEEKS': [
                            'NON-O Retirement',
                            'NON-O Thai Child',
                            'NON-O Volunteer'
                        ],
                        '4-5 WEEKS': [
                            'NON-O Marriage/Thai Wife',
                            '1 Year Marriage Extension'
                        ]
                    };

                    if (
                        statusType === 'visa' &&
                        TIMESPANS['3-4 DAYS'].filter(value => status.types.includes(value)).length &&
                        !TIMESPANS['3-4 WEEKS'].filter(value => status.types.includes(value)).length
                    ) {
                        description = `7-10 BUSINESS DAYS`;
                    } else if (
                        TIMESPANS['3-4 WEEKS'].filter(value => status.types.includes(value)).length
                    ) {
                        description = `3-4 WEEKS`;
                    } else if (
                        TIMESPANS['1-2 WEEKS'].filter(value => status.types.includes(value)).length
                    ) {
                        description = `1-2 WEEKS`;
                    } else if (
                        TIMESPANS['4-5 WEEKS'].filter(value => status.types.includes(value)).length
                    ) {
                        description = `4-5 WEEKS`;
                    }

                    // console.log(status.types, description, TIMESPANS['3-4 WEEKS'].filter(value => status.types.includes(value)))
                }

                let itemDescription:any;

                if ((item.type === 'PASSPORT READY FOR RETURN' || item.type === 'READY FOR RETURN') && item.description && item.description.match(/[A-Z0-9]{12,}/)) {
                    itemDescription = <Tracking trackingNumber={item.description} />;
                } else {
                    itemDescription = item.description;
                }

				return <Steps.Item key={key} title={<div className={styles.step}>
					<h2>{eventType}</h2>
					{item.type === 'PAYMENT CONFIRMED' ? <div className={styles.stepDescription}><b>฿{status.fee}</b><br />{statusType === 'visa' ? status.types.map((item:any, key:number) => {
						return <div key={key}>- {item}</div>;
					}) : null}</div> : null}
                    {description ? <div className={styles.stepDescription}>
                        {description}
                    </div> : null}
					<div className={styles.stepImages}>
						{item.images?.map((item:any, key:number) => {
							return <img src={item} key={key} width={100} alt={'Timeline Event'} onClick={onShowImageClick({url: item})} />
						})}
					</div>
                    {itemDescription ? <div className={styles.stepDescription}>
                        {itemDescription}
                    </div> : null}
				</div>} />;	
			})}
		</Steps>

		{imageViewerImage && imageViewerImage.url ? <Dialog classes={imageViewerClasses} fullScreen onClose={onImageViewerClose} aria-labelledby="simple-dialog-title" open={true}>
			<img style={{maxWidth: window.innerWidth - 100, maxHeight: window.innerHeight - 100}} src={String(imageViewerImage.url).replace(/uploads-thaivisacentre-com\.s3\.amazonaws\.com/, 'uploads.thaivisacentre.com')} onClick={onImageViewerClose} alt="enlarged" />
		</Dialog>: null}
	</div>;
}

export default Timeline;