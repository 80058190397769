import React, {useState} from 'react';
import styles from './index.module.scss';
import 'rsuite/dist/styles/rsuite-default.css';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loader from '../../Loader';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Overlay from '../../Overlay';
import { useDropzone } from 'react-dropzone';
import Promise from 'bluebird';
import 'rsuite/dist/styles/rsuite-default.css';
import { Steps } from 'rsuite';
import Confirmation from './Confirmation';
import PassportLookup from './PassportLookup';
import Jimp from 'jimp';

const PASSPORT_REPORTS_QUERY_RESULT = gql`
	query GetPassportReports ($passport_token:String!) {
		passport: public_passport_reports(passport_token: $passport_token) {
			first_name
			last_name
            reports
		}
	}
`;

const INSERT_REPORT_REQUEST_QUERY_RESULT = gql`
	mutation InsertReportRequest($passport_token: String!, $timeline_images: jsonb!) {
		report: public_insert_report_request(passport_token: $passport_token, timeline_images: $timeline_images) {
			id
		}
	}
`;

function StepDropzone (props:any) {
	const [isUploading, setIsUploading] = useState(false);

	async function uploadPhoto(image: any) {
		setIsUploading(true);
		image = await Jimp.read(image.preview);

		if (image.bitmap.width > 1000) {
			image = await image.resize(1500, Jimp.AUTO, Jimp.RESIZE_BEZIER);
		}
		image = new Blob([await image.quality(80).getBufferAsync(Jimp.MIME_JPEG)], {type: 'image/jpeg'});

		const formData  = new FormData();
		formData.append('image', image);
		formData.append('token', props.passport_token);
		
		
		const response = await fetch('https://rest.thaivisacentre.com/public_upload_photo', {
			method: 'POST',
			body: formData
		});
		setIsUploading(false);

		return await response.json();
	}

	const {getRootProps, getInputProps} = useDropzone({
		onDrop: async (acceptedFiles: any) => {
			acceptedFiles = acceptedFiles.map((acceptedFile:any) => {
				return Object.assign(acceptedFile, {
					preview: URL.createObjectURL(acceptedFile)
				});
			});

			if (props.onUploadChange) {
				props.onUploadChange(true);
			}

			let images = (await Promise.map(acceptedFiles, uploadPhoto)).map((item:any) => item.image);
			if (props.onUploadComplete) {
				props.onUploadComplete(props.step, images);
			}

			if (props.onUploadChange) {
				props.onUploadChange(false);
			}
		}
	});

	return <div {...getRootProps({className: styles.dropzone})} style={{opacity: isUploading ? 0.7 : 1}}>
		{(() => {
			return <>
				<input {...getInputProps()} />
				<span>{isUploading ? 'UPLOADING..' : props.buttonLabel}</span>
			</>;
		})()}
	</div>;
}

function ReportingRequest(props:any) {
	const { passport_token } = props;

	const [insertReportRequestQueryResult] = useMutation(INSERT_REPORT_REQUEST_QUERY_RESULT, {});
	const [userProvidedData, setUserProvidedData] = useState<any>({});
	const [step, setStep] = useState('one');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [skipStepFive, setSkipStepFive] = useState<any>(null);
	const [skipStepSix, setSkipStepSix] = useState<any>(null);
	const [showPaymentInfo, setShowPaymentInfo] = useState(false);

	const COST = '฿500';

	if (isSubmitting) {
		return <Loader message="LOADING" width={150} />;
	}

	async function submitReportRequest () {
		if (isSubmitting) {
			return false;
		}
		setIsSubmitting(true);

		let response:any = await insertReportRequestQueryResult({
			variables: {
				passport_token,
				timeline_images: [
					[].concat(
						userProvidedData.two?.images,
						userProvidedData.three?.images,
						userProvidedData.four?.images,
						userProvidedData.five?.images
					).filter((item:any) => item),
					userProvidedData.six?.images
				]
			}
		});

		// setIsSubmitting(false);

		window.location.href = `/passport/${passport_token}/report/${response.data.report.id}`;
	}

	function onUploadComplete (step:string, images:any) {
		console.log(step, images);
		let data = JSON.parse(JSON.stringify(userProvidedData));
		if (!data[step]) {
			data[step] = {
				images: []
			};
		}

		data[step].images = data[step].images.concat(images);

		setUserProvidedData(data);
		console.log(data);
	}

	function renderStepOne () {
		if (showPaymentInfo) {
			return <div>
				<Alert severity="info" icon={false} style={{marginBottom: 15}}>
					Please transfer <b>{COST}</b> to the following account.<br />
					<br />
					<b>Bank</b> Kasikorn Bank<br />
					<b>Name</b> MRS. GRACE PUNN-NAK SCIRA<br />
					<b>Account Number</b> 092-3-93371-9<br />
					<br />
					Once you have done this make sure to keep a photo of payment receipt.
				</Alert>
				<Button variant="contained" style={{float: 'right'}} onClick={() => setShowPaymentInfo(false)} color="secondary" size="large">
					<b>BACK</b>
				</Button>
			</div>;
		}
		return <div>
			<Alert severity="warning" icon={false} style={{marginBottom: 15, fontWeight: 'bold'}}>
				<div>MUST BE 5-10 DAYS BEFORE DUE DATE!</div>
			</Alert>
			<Alert severity="info" icon={false} style={{marginBottom: 15}}>
				<div style={{marginBottom: 10, fontSize: 22}}><b>90 Day Report Request</b></div>
				<div style={{marginBottom: 10}}>Hello <b>{props.passport.first_name} {props.passport.last_name}</b>,</div>
				<div style={{marginBottom: 10}}>We will need you to provide some digital photos in order to complete your request.</div>
				<div style={{marginBottom: 10}}>You will be asked for <b>photos</b> of the following items:</div>
				<ul>
					<li>Last Entry Stamp</li>
					<li>Current Visa Stamp</li>
					<li>TM6 (Arrival/Departure Card)</li>
					<li>Last 90 Day Report (if applicable)</li>
					<li>Reciept of {COST} Payment<br />
					<span style={{fontWeight: 'bold', color: '#f50057', cursor: 'pointer'}} onClick={() => setShowPaymentInfo(true)}>(click HERE to make payment)</span>
					</li>
				</ul>
				<div style={{marginBottom: 10}}>If you have <b>CLEAR PHOTOS</b> of the above then you may proceed.</div>
			</Alert>
			<div>
				<Button variant="contained" style={{float: 'right'}} onClick={() => setStep('two')} color="secondary" size="large">
					<b>NEXT</b>
				</Button>
			</div>
		</div>;
	}

	function renderStepTwo () {
		return <div>
			<div className={styles.stepsContainer}>
				<Steps current={0}>
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
				</Steps>
			</div>
			<Alert severity="info" icon={false} style={{marginBottom: 15}}>
				<div>Please upload a photo of your last <b>ENTRY STAMP</b>.</div>
			</Alert>
			<StepDropzone passport_token={passport_token} buttonLabel={<div>CLICK TO<br /><b>UPLOAD</b> PHOTOS <br /> OF <b>ENTRY STAMP</b></div>} step="two" onUploadComplete={onUploadComplete} />
			{userProvidedData?.['two']?.images?.length ? <div className={styles.photo}>
				<div className={styles.stepImages}>
					{userProvidedData?.['two']?.images?.map((image:any, key:number) => {
						return <div className={styles.stepImage} key={key}>
							
							<div className={styles.stepImageContainer}>
								<img src={image} width={100} alt={'Entry Stamp'} />
								<div className={styles.stepImageDeleteIcon}>
				
								</div>
							</div>
						</div>;
					})}
				</div>
			</div> : null}
			<div>
				<Button variant="contained" style={{float: 'right'}} onClick={() => setStep('three')} color="secondary" size="large" disabled={!userProvidedData?.['two']?.images?.length}>
					<b>NEXT</b>
				</Button>
			</div>
		</div>;
	}

	function renderStepThree () {
		return <div>
			<div className={styles.stepsContainer}>
				<Steps current={1}>
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
				</Steps>
			</div>
			<Alert severity="info" icon={false} style={{marginBottom: 15}}>
				<div>Please upload a photo of your <b>CURRENT VISA</b>.</div>
			</Alert>
			<StepDropzone passport_token={passport_token} buttonLabel={<div>CLICK TO<br /><b>UPLOAD</b> PHOTOS <br /> OF <b>CURRENT VISA</b></div>} step="three" onUploadComplete={onUploadComplete} />
			{userProvidedData?.['three']?.images?.length ? <div className={styles.photo}>
				<div className={styles.stepImages}>
					{userProvidedData?.['three']?.images?.map((image:any, key:number) => {
						return <div className={styles.stepImage} key={key}>
							
							<div className={styles.stepImageContainer}>
								<img src={image} width={100} alt={'Current Visa'} />
								<div className={styles.stepImageDeleteIcon}>
				
								</div>
							</div>
						</div>;
					})}
				</div>
			</div> : null}
			<div>
				<Button variant="contained" style={{float: 'right'}} onClick={() => setStep('four')} color="secondary" size="large" disabled={!userProvidedData?.['three']?.images?.length}>
					<b>NEXT</b>
				</Button>
			</div>
		</div>;
	}

	function renderStepFour () {
		return <div>
			<div className={styles.stepsContainer}>
				<Steps current={2}>
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
				</Steps>
			</div>
			<Alert severity="info" icon={false} style={{marginBottom: 15}}>
				<div>Please upload a photo of your last <b>TM6 CARD</b>.</div>
			</Alert>
			<StepDropzone passport_token={passport_token} buttonLabel={<div>CLICK TO<br /><b>UPLOAD</b> PHOTOS <br /> OF <b>TM6 CARD</b></div>} step="four" onUploadComplete={onUploadComplete} />
			{userProvidedData?.['four']?.images?.length ? <div className={styles.photo}>
				<div className={styles.stepImages}>
					{userProvidedData?.['four']?.images?.map((image:any, key:number) => {
						return <div className={styles.stepImage} key={key}>
							
							<div className={styles.stepImageContainer}>
								<img src={image} width={100} alt={'TM6 Card'} />
								<div className={styles.stepImageDeleteIcon}>
				
								</div>
							</div>
						</div>;
					})}
				</div>
			</div> : null}
			<div>
				<Button variant="contained" style={{float: 'right'}} onClick={() => setStep('five')} color="secondary" size="large" disabled={!userProvidedData?.['four']?.images?.length}>
					<b>NEXT</b>
				</Button>
			</div>
		</div>;
	}

	function renderStepFive () {
		return <div>
			<div className={styles.stepsContainer}>
				<Steps current={3}>
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
				</Steps>
			</div>
			<Alert severity="info" icon={false} style={{marginBottom: 15}}>
				<div>Please upload photo of your last <b>90 DAY REPORT</b>.</div>
			</Alert>
			<StepDropzone passport_token={passport_token} buttonLabel={<div>CLICK TO<br /><b>UPLOAD</b> PHOTO <br /> OF <b>90 DAY REPORT</b></div>} step="five" onUploadComplete={onUploadComplete} />
			{userProvidedData?.['five']?.images?.length ? <div className={styles.photo}>
				<div className={styles.stepImages}>
					{userProvidedData?.['five']?.images?.map((image:any, key:number) => {
						return <div className={styles.stepImage} key={key}>
							
							<div className={styles.stepImageContainer}>
								<img src={image} width={100} alt={'90 Day Report'} />
								<div className={styles.stepImageDeleteIcon}>
				
								</div>
							</div>
						</div>;
					})}
				</div>
			</div> : null}
			<div>
				<Button variant="contained" style={{float: 'right'}} onClick={() => setStep('six')} color="secondary" size="large" disabled={!userProvidedData?.['five']?.images?.length}>
					<b>NEXT</b>
				</Button>
			</div>
			{skipStepFive === null ? <Confirmation 
				onSubmit={(confirmed:boolean) => {
					if (!confirmed) {
						setSkipStepFive(true);
						setStep('six');
					} else {
						setSkipStepFive(false);
					}
				}} 
				title={<span>Have you done a <b>90 Day Report</b> since your last entry into <b>Thailand</b>?</span>} 
				noLabel="no" 
				yesLabel="yes" /> : null}
		</div>;
	}

	function renderStepSix () {
		return <div>
			<div className={styles.stepsContainer}>
				<Steps current={4}>
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
					<Steps.Item />
				</Steps>
			</div>
			<Alert severity="info" icon={false} style={{marginBottom: 15}}>
				<div>Please upload a photo of <b>PROOF OF PAYMENT</b>.</div>
			</Alert>
			<StepDropzone passport_token={passport_token} buttonLabel={<div>CLICK TO<br /><b>UPLOAD</b> PHOTO <br /> OF <b>PROOF OF PAYMENT</b></div>} step="six" onUploadComplete={onUploadComplete} />
			{userProvidedData?.['six']?.images?.length ? <div className={styles.photo}>
				<div className={styles.stepImages}>
					{userProvidedData?.['six']?.images?.map((image:any, key:number) => {
						return <div className={styles.stepImage} key={key}>
							
							<div className={styles.stepImageContainer}>
								<img src={image} width={100} alt={'Proof of Payment'} />
								<div className={styles.stepImageDeleteIcon}>
				
								</div>
							</div>
						</div>;
					})}
				</div>
			</div> : null}
			<div>
				<Button variant="contained" style={{float: 'right'}} onClick={() => {
					submitReportRequest();
				}} color="secondary" size="large" disabled={!userProvidedData?.['six']?.images?.length}>
					<b>NEXT</b>
				</Button>
			</div>
			{skipStepSix === null ? <Confirmation 
				onSubmit={(confirmed:boolean) => {
					if (!confirmed) {
						setSkipStepSix(true);
						submitReportRequest();
					} else {
						setSkipStepSix(false);
					}
				}} 
				title={<span>Do you have proof of payment for <b>{COST}</b>, or a image of <b>90 day reporting fee waiver</b>?</span>} 
				noLabel="no" 
				yesLabel="yes" /> : null}
		</div>;
	}

	return <div>
		{step === 'one' ? renderStepOne() : null}
		{step === 'two' ? renderStepTwo() : null}
		{step === 'three' ? renderStepThree() : null}
		{step === 'four' ? renderStepFour() : null}
		{step === 'five' ? renderStepFive() : null}
		{step === 'six' ? renderStepSix() : null}
	</div>
}


function App(props:any) {
	// const [isRequestingReport, setIsRequestingReport] = useState(false);
	
	let { data, loading, error } = useQuery(
		PASSPORT_REPORTS_QUERY_RESULT,
		{ skip: !props.match.params.passport_token, variables: { 
			passport_token: props.match.params.passport_token
		}, fetchPolicy: 'cache-first' }
	);

	if (!props.match.params.passport_token) {
		return <div className={styles.App}>
			<PassportLookup props={props} />
		</div>;
	}

	let passport = data?.passport;
	
	if (loading || error) {
		return <Loader message="LOADING" width={150} />;
	}
	
	let inProgressReport = data.passport.reports.slice(0).filter((report:any) => {
		return report.timeline_current_step !== 'COMPLETED';
	}).shift();

	if (inProgressReport) {
		return <Redirect to={`/passport/${props.match.params.passport_token}/report/${inProgressReport.id}`} />;
	}

	return (
		<div className={styles.App}>
			<Alert severity="success" icon={false} style={{marginBottom: 15, fontSize: 13}}>This is a <b>secure link</b>, and your information is <b>safe</b>.</Alert>
			
			<Alert severity="info" icon={false} style={{marginBottom: 15, marginTop: 15}}>
				<div>Hello <b>{passport.first_name} {passport.last_name}</b>,</div>
				<br />
				<div>This is the <b>Thai Visa Centre</b> 90 day self-reporting request system.</div>
				<br />
				<div>Here you may initiate, or view past 90 day reports.</div>
			</Alert>

			<div className={styles.reports}>
				{passport.reports.map((report:any, key:number) => {
					return <div key={key} className={styles.report}>
						<div className={styles.date}>{moment(report.created_at).format('DD/MM/YYYY')}</div>
						<div className={styles.status}>STATUS - {report.timeline_current_step}</div>
					</div>;
				})}
			</div>

			<div style={{textAlign: 'center', marginTop: 40}}>
				<Button variant="contained" onClick={() => {}} color="secondary" size="large" fullWidth>
					<b>REQUEST A 90 DAY REPORT</b>
				</Button>
			</div>

			<Overlay>
				<ReportingRequest passport={passport} passport_token={props.match.params.passport_token} />
			</Overlay>
		</div>
	);
}

export default App;