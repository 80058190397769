import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Confirmation(props:any) {
	const {onSubmit, message, noLabel = 'NO', yesLabel = 'YES', title} = props;

	return <div>
		<Dialog open={true} BackdropProps={{ style: { backgroundColor: "#fff" } }}>
			<DialogTitle>{title}</DialogTitle>
			{message ? <DialogContent>
				<DialogContentText>
					{message}
				</DialogContentText>
			</DialogContent> : null}
			<DialogActions>
				<Button variant="contained" color="default" onClick={() => {
					if (onSubmit) {
						onSubmit(false);
					}
				}} style={{float: 'left'}}>{noLabel}</Button>
				<Button variant="contained" color="secondary" onClick={() => {
					if (onSubmit) {
						onSubmit(true);
					}
				}} autoFocus>{yesLabel}</Button>
			</DialogActions>
		</Dialog>
	</div>;
}