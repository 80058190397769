import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

let production = {
	"graphql_server": "https://graphql.thaivisacentre.com/v1/graphql",
};

let development = {
	"graphql_server": "http://graphql.thaivisacentre.com/v1/graphql",
};

let config:any;

if (window.location.hostname === 'localhost') {
	config = development;
} else {
	config = production;
}

export const httpLink = new HttpLink({
	uri: config.graphql_server
});

export default new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache()
});